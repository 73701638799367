<template>
  <section id="hero" class="section hero">
    <div class="hero-content">
      <h1 class="hero-content__title" v-intersection="{section: 'hero'}">
        Психолог <br /> в кармане
      </h1>
      <img class="hero-content__image" alt="Картинка сеанса психолога" src="img/main-image.png">
      <h2 class="hero-content__subtitle">Ваш путь к ментальному здоровью</h2>
      <p class="hero-content__text">
        Программно-аналитический комплекс для подбора узкопрофильных психологов и поддержки ментального здоровья
      </p>
      <Button class="button" :link="'support'" :action="'SCROLL'">Поддержать</Button>
    </div>
    <div class="hero-bottom">
      <h2 class="hero-bottom__title">
        Стартап проект
      </h2>
      <p class="hero-bottom__text">
        Разработка приложения AI-агрегатора услуг психологической консультации, в котором услуги будут оказывать люди с
        ограниченными возможностями здоровья по зрению, имеющими профильное образование и подтверждённые навыки ведения
        консультаций.
      </p>
    </div>
  </section>
</template>

<script>
  import Button from "@/components/Button";
  import scrollMixin from "@/mixins/scrollMixin";

  export default {
    name: 'HeroSection',
    components: {Button},
    data: () => {
      return {}
    },
    mixins: [scrollMixin],
    methods: {}
  }
</script>

<style lang="scss" scoped>
  @import "./index.scss";
</style>