<template>
  <svg class="footer-icon" width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_303_208)">
      <path
        d="M34.5 0C25.3521 0 16.5708 3.63706 10.1074 10.1047C3.63739 16.575 0.00179222 25.3498 0 34.5C0 43.6463 3.63867 52.4276 10.1074 58.8953C16.5708 65.3629 25.3521 69 34.5 69C43.6479 69 52.4292 65.3629 58.8926 58.8953C65.3613 52.4276 69 43.6463 69 34.5C69 25.3537 65.3613 16.5724 58.8926 10.1047C52.4292 3.63706 43.6479 0 34.5 0Z"
        fill="url(#paint0_linear_303_208)"
      />
      <path
        class="footer-icon__inner"
        d="M15.6167 34.1356C25.6756 29.7541 32.3815 26.8653 35.7345 25.4697C45.319 21.4844 47.3082 20.7922 48.6073 20.7688C48.893 20.7642 49.5291 20.8348 49.9442 21.1704C50.2892 21.4534 50.3862 21.8361 50.4347 22.1048C50.4778 22.3733 50.5371 22.9851 50.4886 23.4627C49.9711 28.918 47.7232 42.1563 46.5804 48.2666C46.1007 50.852 45.1465 51.7188 44.2247 51.8034C42.2194 51.9878 40.6993 50.4795 38.7586 49.2078C35.7237 47.217 34.0095 45.9783 31.0608 44.036C27.6539 41.7914 29.8641 40.5575 31.8047 38.5414C32.3114 38.0136 41.1413 29.9843 41.3084 29.256C41.33 29.1649 41.3515 28.8253 41.1467 28.6463C40.9472 28.4668 40.6507 28.5283 40.4351 28.5768C40.1278 28.6458 35.2817 31.8522 25.8804 38.1953C24.5058 39.1408 23.2606 39.6017 22.1393 39.5775C20.9103 39.551 18.5384 38.881 16.7757 38.3085C14.6194 37.6061 12.8998 37.2347 13.0507 36.0417C13.1262 35.4207 13.9833 34.7852 15.6167 34.1356Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear_303_208" x1="3450" y1="0" x2="3450" y2="6900" gradientUnits="userSpaceOnUse">
        <stop stop-color="#10B5CB"/>
        <stop offset="1" stop-color="#229ED9"/>
      </linearGradient>
      <clipPath id="clip0_303_208">
        <rect width="69" height="69" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<style lang="scss" scoped>
  .footer-icon {
    path {
      fill: var(--text-color-bright);
    }

    &__inner {
      fill: var(--bg-color) !important;
    }
  }
</style>