<template>
  <svg class="footer-icon" width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.5113 -0.0786133H22.5211C4.2641 -0.0786133 -0.0786133 4.2641 -0.0786133 22.4885V42.4787C-0.0786133 60.7324 4.23152 65.0784 22.4885 65.0784H42.4787C60.7324 65.0784 65.0784 60.7683 65.0784 42.5113V22.5211C65.0784 4.2641 60.7683 -0.0786133 42.5113 -0.0786133ZM52.5226 46.4109H47.7694C45.9711 46.4109 45.4303 44.9547 42.205 41.7294C39.3902 39.0156 38.2011 38.6768 37.4877 38.6768C36.5038 38.6768 36.2334 38.9472 36.2334 40.3057V44.58C36.2334 45.7365 35.8587 46.4142 32.8387 46.4142C29.9078 46.2172 27.0655 45.3267 24.5462 43.8161C22.0268 42.3055 19.9023 40.218 18.3478 37.7255C14.6572 33.1319 12.0893 27.7406 10.8482 21.9803C10.8482 21.2668 11.1186 20.6218 12.4771 20.6218H17.2271C18.4488 20.6218 18.8886 21.1658 19.3675 22.4201C21.6741 29.2095 25.6095 35.1127 27.2059 35.1127C27.8184 35.1127 28.0855 34.8423 28.0855 33.3144V26.323C27.8835 23.1336 26.1894 22.8632 26.1894 21.7099C26.2112 21.4057 26.3506 21.122 26.5781 20.9188C26.8055 20.7157 27.1032 20.6091 27.4079 20.6218H34.8749C35.8946 20.6218 36.2334 21.13 36.2334 22.3517V31.7864C36.2334 32.8061 36.6732 33.1449 36.9794 33.1449C37.5919 33.1449 38.0643 32.8061 39.185 31.6854C41.5921 28.7499 43.5588 25.4795 45.0231 21.977C45.1727 21.5563 45.456 21.1961 45.8295 20.9514C46.203 20.7067 46.6463 20.591 47.0918 20.6218H51.845C53.2687 20.6218 53.5717 21.3352 53.2687 22.3517C51.5404 26.2234 49.4018 29.8986 46.8898 33.3144C46.3783 34.0962 46.1731 34.5035 46.8898 35.4189C47.3622 36.1324 49.027 37.5235 50.1477 38.8462C51.7763 40.4707 53.1287 42.3502 54.1516 44.4106C54.5588 45.7333 53.8779 46.4109 52.5226 46.4109Z"
      fill="#10B5CB"
    />
  </svg>
</template>

<style lang="scss" scoped>
  .footer-icon {
    path {
      fill: var(--text-color-bright);
    }
  }
</style>