<template>
  <div class="task-card">
    <div class="task-card-image">
      <img :alt="task.text" :src="task.imageUrl" class="task-card-image__img">
    </div>
    <div class="task-card__text">{{ task.text }}</div>
  </div>
</template>

<script>
  export default {
    props: {
      task: {
        imageUrl: {
          type: String,
          required: true
        },
        text: {
          type: String,
          required: true
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import './index.scss';
</style>