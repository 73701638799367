<template>
  <section id="contacts" class="section contacts">
    <div class="section-title">
      <span class="section-title__number">4</span>
      <h2 class="section-title__text" v-intersection="{section: 'contacts'}">
        КОНТАКТЫ
      </h2>
    </div>
    <div class="contacts-content">
      <div class="contacts-content-block">
        <span class="contacts-content-block__text">
          Если у вас есть вопросы о разработке приложения, или вы хотите принять участие в его создании, напишите нам
        </span>
        <Button
          href="https://t.me/Udvayzer"
          target="_blank"
          class="button"
          :style="{'text-align': 'center'}"
        >
          Написать
        </Button>
      </div>
      <div class="contacts-content-block">
        <span class="contacts-content-block__text">
          Также вы можете скачать полный бизнес-план проекта приложения “Психолог в кармане”,
          чтобы узнать больше о нашей миссии, стратегии и как вы можете внести свой вклад в создание инклюзивного общества
        </span>
        <Button
          href="pdf/ПВК-общая%20презентация.pdf"
          download
          class="button"
          :style="{'text-align': 'center'}"
        >
          Скачать
        </Button>
      </div>
    </div>
  </section>
</template>

<script>
  import Button from "@/components/Button";

  export default {
    components: {Button},
    data: () => {
      return {}
    }
  }
</script>

<style lang="scss" scoped>
  @import './index.scss';
</style>