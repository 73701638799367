import { createApp } from 'vue';
import { createPinia } from "pinia";
import App from './App.vue';
import VIntersection from "@/directives/VIntersection";

const pinia = createPinia();
const app = createApp(App);

app.directive('intersection', VIntersection);

app.use(pinia);
app.mount('#app');