<template>
  <svg class="footer-icon" width="66" height="49" viewBox="0 0 66 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M59.1668 0.833496C60.736 0.833496 62.2409 1.45686 63.3505 2.56645C64.4601 3.67604 65.0835 5.18096 65.0835 6.75016V42.2502C65.0835 43.8194 64.4601 45.3243 63.3505 46.4339C62.2409 47.5435 60.736 48.1668 59.1668 48.1668H11.8335C10.2643 48.1668 8.75935 47.5435 7.64976 46.4339C6.54017 45.3243 5.91681 43.8194 5.91681 42.2502V39.2918H11.8335V42.2502H59.1668V10.9599L38.6508 31.4759C37.8186 32.3078 36.6901 32.7751 35.5135 32.7751C34.3368 32.7751 33.2083 32.3078 32.3761 31.4759L11.8335 10.9332V12.6668H5.91681V6.75016C5.91681 5.18096 6.54017 3.67604 7.64976 2.56645C8.75935 1.45686 10.2643 0.833496 11.8335 0.833496H59.1668ZM17.7501 27.4585C18.5042 27.4593 19.2294 27.7481 19.7777 28.2657C20.326 28.7833 20.6559 29.4907 20.7001 30.2435C20.7443 30.9962 20.4994 31.7374 20.0154 32.3156C19.5315 32.8938 18.845 33.2654 18.0963 33.3545L17.7501 33.3752H2.95847C2.20445 33.3743 1.47921 33.0856 0.930926 32.568C0.38264 32.0504 0.0526954 31.3429 0.00850641 30.5902C-0.0356826 29.8375 0.209219 29.0963 0.693174 28.5181C1.17713 27.9399 1.86361 27.5683 2.61235 27.4792L2.95847 27.4585H17.7501ZM14.7918 18.5835C15.5764 18.5835 16.3289 18.8952 16.8837 19.45C17.4385 20.0048 17.7501 20.7572 17.7501 21.5418C17.7501 22.3264 17.4385 23.0789 16.8837 23.6337C16.3289 24.1885 15.5764 24.5002 14.7918 24.5002H5.91681C5.13221 24.5002 4.37974 24.1885 3.82495 23.6337C3.27015 23.0789 2.95847 22.3264 2.95847 21.5418C2.95847 20.7572 3.27015 20.0048 3.82495 19.45C4.37974 18.8952 5.13221 18.5835 5.91681 18.5835H14.7918Z"
      fill="#10B5CB"
    />
  </svg>
</template>

<style lang="scss" scoped>
  .footer-icon {
    path {
      fill: var(--text-color-bright);
    }
  }
</style>