<template>
  <header class="header" :class="{'header-fade': windowWidth <= 960 && navVisible}">
    <a class="header-logo" href="/" @click="(e) => navClickHandler(e,'top')">
      <LogoIcon class="header-logo__icon"/>
    </a>
    <nav
      class="header-nav"
      v-if="windowWidth > 960"
    >
      <ul class="header-nav-list">
        <li
          class="header-nav-list-item"
          v-for="section in sections"
          :key="section.sectionId"
        >
          <a
            class="header-nav__link"
            :class="{'header-nav__link-active': section.sectionId === currentSection}"
            :href="`#${section.sectionId}`"
            @click="(e) => navClickHandler(e, section.sectionId)"
          >
            {{ section.title }}
          </a>
        </li>
        <li class="header-nav-list-item">
          <button class="header-ay-btn">
            <span
              class="header-ay-btn__text"
              v-if="switchThemeVisible"
              @click="changeSwitchThemeVisible"
            >
              Включить обычную версию
            </span>
            <GlassesIcon
              :class="{'glasses-icon-active': switchThemeVisible}"
              @click="changeSwitchThemeVisible"
            />
          </button>
        </li>
      </ul>
    </nav>
    <nav class="header-nav" v-else>
      <MenuIcon
        class="header-nav__button"
        :class="{'menu-icon-active': navVisible}"
        @click="changeNavVisible"
      />
      <div class="header-nav-list-wrap" v-if="navVisible">
        <ul class="header-nav-list">
          <li class="header-nav-list-item">
            <GlassesIcon
              :class="{'glasses-icon-active': switchThemeVisible}"
              @click="changeSwitchThemeVisible"
            />
          </li>
          <li
            class="header-nav-list-item"
            v-for="section in sections"
            :key="section.sectionId"
          >
            <a
              class="header-nav__link"
              :class="{'header-nav__link-active': section.sectionId === currentSection}"
              :href="`#${section.sectionId}`"
              @click="(e) => navClickHandler(e, section.sectionId)"
            >
              {{ section.title }}
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <SwitchThemeModal
      :visible="switchThemeVisible"
      :window-width="windowWidth"
      @switchVisible="switchThemeVisible = !switchThemeVisible"
    />
  </header>
</template>

<script>
  import {mapState} from "pinia";
  import {useSectionStore} from "@/store/sectionStore";

  import SwitchThemeModal from "@/components/Header/SwitchThemeModal";
  import LogoIcon from "@/assets/svg/icons/LogoIcon";
  import GlassesIcon from "@/assets/svg/icons/GlassesIcon";
  import MenuIcon from "@/assets/svg/icons/MenuIcon";
  import scrollMixin from "@/mixins/scrollMixin";

  export default {
    name: 'Header',
    components: {
      SwitchThemeModal,
      LogoIcon,
      GlassesIcon,
      MenuIcon
    },

    data: () => {
      return {
        windowWidth: window.innerWidth,
        navVisible: false,
        switchThemeVisible: false,
        sections: [
          {
            sectionId: 'about',
            title: 'О проекте'
          },
          {
            sectionId: 'peoples',
            title: 'О людях'
          },
          {
            sectionId: 'consultation',
            title: 'О консультации'
          },
          {
            sectionId: 'tasks',
            title: 'Приложение'
          },
          /*{
            sectionId: 'team',
            title: 'О команде'
          },
          {
            sectionId: 'reviews',
            title: 'Отзывы'
          },*/
          {
            sectionId: 'contacts',
            title: 'Контакты'
          },
          {
            sectionId: 'support',
            title: 'Поддержать'
          },
        ],
      }
    },
    computed: {
      ...mapState(useSectionStore, ['currentSection'])
    },
    created() {
      window.addEventListener("resize", this.onResize);
    },
    unmounted() {
      window.removeEventListener("resize", this.onResize);
    },
    mixins: [scrollMixin],
    methods: {
      changeSwitchThemeVisible() {
        if (!this.switchThemeVisible) {
          document.body.style.overflowY = 'hidden'
          this.switchThemeVisible = true
        } else {
          document.body.style.overflowY = null
          this.switchThemeVisible = false
        }
      },
      changeNavVisible() {
        document.body.style.overflowY = !this.navVisible ? 'hidden' : null
        this.switchThemeVisible = false
        this.navVisible = !this.navVisible
      },
      navClickHandler(e, section) {
        e.preventDefault()
        if (!this.switchThemeVisible) {
          this.scrollTo(section)
          if (this.windowWidth <= 960) {
            this.navVisible = false
            document.body.style.overflowY = null
          }
        }
      },
      onResize(e) {
        this.windowWidth = e.target.innerWidth
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "./index.scss";
</style>