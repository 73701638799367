import {useSectionStore} from "@/store/sectionStore";

export default {
    mounted(el, binding) {
        const options = {
            rootMargin: '0px 0px -50% 0px'
        }
        const store = useSectionStore()

        const callback = (entries) => {
            if (entries[0].isIntersecting) {
                const section = binding.value.section
                store.changeCurrentSection(section === 'hero' ? '' : section)
            }
        }
        const observer = new IntersectionObserver(callback, options)
        observer.observe(el)
    }
}