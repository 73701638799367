<template>
  <section id="about" class="section about">
    <div class="section-title">
      <h2 class="section-title__text" v-intersection="{section: 'about'}">
        О ПРОЕКТЕ
      </h2>
    </div>
    <div class="about-content">
      <span class="about-content__text">
        Мы занялись разработкой приложения "Психолог в кармане", чтобы решить 3 важных вопроса:
      </span>
      <ol class="about-content-list">
        <li class="about-content-list__item">
          Разработка интерфейса и полной архитектуры приложения предназначенного
          для работы людей с ограниченными возможностями здоровья, имеющими профильное
          высшее образование и прошедшим дополнительные курсы повышения
          квалификации в качестве психологов-консультантов;
        </li>
        <li class="about-content-list__item">
          Обеспечение качественной психологической консультацией сотрудников государственных
          и коммерческих компаний, а также клиентов реабилитационных центров;
        </li>
        <li class="about-content-list__item">
          Качественный подбор психолога на основе диагностики состояния клиента
          с помощью искусственного интеллекта, а не только отталкивающийся от запроса клиента.
        </li>
      </ol>
    </div>
    <div class="about-video-title">
      Посмотри видео и узнай подробнее
    </div>
    <div class="about-video-frame">
      <div class="about-video-frame-container">
        <iframe
          src="https://www.youtube.com/embed/S4OXnqWMpH4"
          title="Психолог  в кармане. Суть проекта"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </div>
      <span class="about-video-frame-desc">Психолог в кармане. Суть проекта</span>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import './index.scss';
</style>