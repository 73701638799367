<template>
  <section id="consultation" class="section consultation">
    <div class="consultation-content">
      <div class="section-title">
        <span class="section-title__number">2</span>
        <h2 class="section-title__text" v-intersection="{section: 'consultation'}">
          О консультации
        </h2>
      </div>
      <img class="consultation-content__image" alt="Картинка консультации" src="img/consultation-image.png">
      <div class="consultation-content__desc">
        Наши психологи обладают необходимыми профессиональными компетенциями, этической ответственностью и умеют найти
        индивидуальный подход к каждому клиенту.
      </div>
      <p class="consultation-content__text">
        Мы делаем особенный акцент на предъявляемых требованиях к профессиональной
        подготовке наших сотрудников. Ставя во главу угла профессионализм исполнителей
        и создавая благоприятную среду для работы людям с ограниченными
        возможностями здоровья мы обеспечиваем высочайший уровень оказываемых услуг
        с помощью создаваемого нами приложения.
      </p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import './index.scss';
</style>