<template>
  <div v-if="visible" class="modal-wrap">
    <div class="modal">
      <div class="modal-inner">
        <div v-if="windowWidth <= 960" class="modal-row" :style="{ height: max-content }">
          <GlassesIcon @click="$emit('switchVisible')"/>
        </div>
        <div class="modal-row">
          <span class="modal-row__label">Цвет сайта и текста</span>
          <div class="modal-row-btns">
            <div class="modal__button-wrapper">
              <button
                class="modal__button modal__button-theme-default"
                :class="{'modal__button-active': theme === 'default'}"
                @click="switchTheme('default')"
              >
                Стандартный
              </button>
            </div>
            <button
              class="modal__button modal__button-theme-bw"
              :class="{'modal__button-active': theme === 'black-on-white'}"
              @click="switchTheme('black-on-white')"
            >
              Чёрный на белом
            </button>
            <button
              class="modal__button modal__button-theme-wb"
              :class="{'modal__button-active': theme === 'white-on-black'}"
              @click="switchTheme('white-on-black')"
            >
              Белый на черном
            </button>
            <button
              class="modal__button modal__button-theme-green"
              :class="{'modal__button-active': theme === 'green'}"
              @click="switchTheme('green')"
            >
              Зелёный
            </button>
            <button
              class="modal__button modal__button-theme-brown"
              :class="{'modal__button-active': theme === 'brown'}"
              @click="switchTheme('brown')"
            >
              Коричневый
            </button>
            <button
              class="modal__button modal__button-theme-blue"
              :class="{'modal__button-active': theme === 'blue'}"
              @click="switchTheme('blue')"
            >
              Синий
            </button>
          </div>
        </div>
        <div class="modal-row">
          <span class="modal-row__label">Изображения</span>
          <div class="modal-row-btns">
            <button
              class="modal__button"
              :class="{'modal__button-active': showImages === 'true'}"
              @click="switchShowImages('true')"
            >
              Показывать
            </button>
            <button
              class="modal__button"
              :class="{'modal__button-active': showImages === 'false'}"
              @click="switchShowImages('false')"
            >
              Не показывать
            </button>
          </div>
        </div>
        <div class="modal-row">
          <span class="modal-row__label">Шрифт</span>
          <div class="modal-row-btns">
            <button
              class="modal__button modal__button-font-sans-serif"
              :class="{'modal__button-active': fontFamily === 'sans-serif'}"
              @click="switchFontFamily('sans-serif')"
            >
              Без засечек
            </button>
            <button
              class="modal__button modal__button-font-serif"
              :class="{'modal__button-active': fontFamily === 'serif'}"
              @click="switchFontFamily('serif')"
            >
              С засечками
            </button>
          </div>
        </div>
        <div class="modal-row">
          <span class="modal-row__label">Расстояние между буквами</span>
          <div class="modal-row-btns">
            <button
              class="modal__button"
              :class="{'modal__button-active': letterSpacing === 'default'}"
              @click="switchLetterSpacing('default')"
            >
              Стандартное
            </button>
            <button
              class="modal__button modal__button-ls-medium"
              :class="{'modal__button-active': letterSpacing === 'medium'}"
              @click="switchLetterSpacing('medium')"
            >
              Среднее
            </button>
            <button
              class="modal__button modal__button-ls-big"
              :class="{'modal__button-active': letterSpacing === 'big'}"
              @click="switchLetterSpacing('big')"
            >
              Большое
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import GlassesIcon from '@/assets/svg/icons/GlassesIcon';

  export default {
    name: 'SwitchThemeModal',
    components: {
      GlassesIcon
    },
    props: {
      visible: {
        type: Boolean,
        required: true,
      },
      windowWidth: {
        type: Number,
        required: true
      }
    },
    data: () => {
      return {
        theme: localStorage.getItem('data-theme'),
        showImages: localStorage.getItem(`data-show-images`),
        fontFamily: localStorage.getItem(`data-font-family`),
        letterSpacing: localStorage.getItem(`data-letter-spacing`),
      }
    },
    methods: {
      switchTheme(theme) {
        this.theme = theme
        document.body.setAttribute(`data-theme`, theme)
        localStorage.setItem(`data-theme`, theme)
      },
      switchShowImages(value) {
        this.showImages = value
        document.body.setAttribute(`data-show-images`, value)
        localStorage.setItem(`data-show-images`, value)
      },
      switchFontFamily(family) {
        this.fontFamily = family
        document.body.setAttribute(`data-font-family`, family)
        localStorage.setItem(`data-font-family`, family)
      },
      switchLetterSpacing(spacing) {
        this.letterSpacing = spacing
        document.body.setAttribute(`data-letter-spacing`, spacing)
        localStorage.setItem(`data-letter-spacing`, spacing)
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "./index.scss";
</style>