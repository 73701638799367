export default {
    methods: {
        scrollTo(section = 'top') {
            if (section === 'top') {
                window.scrollTo({top: 0, behavior: 'smooth'});
            } else {
                const yOffset = window.innerHeight / 10;
                const element = document.getElementById(section);
                const y = element.getBoundingClientRect().top + window.scrollY - yOffset;

                window.scrollTo({top: y, behavior: 'smooth'});
            }
        },
    }
}