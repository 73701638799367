<template>
  <section id="peoples" class="section peoples">
    <div class="section-title">
      <span class="section-title__number">1</span>
      <h2 class="section-title__text">
        О людях
      </h2>
    </div>
    <img
      class="peoples__image"
      alt="Картинка с людьми"
      src="img/peoples-image.png"
      v-intersection="{section: 'peoples'}"
    >
    <div class="peoples-content">
      <p class="peoples-content__text">
        Мы разрабатываем по-настоящему
        <span class="peoples-content__text-bold">инклюзивное программное обеспечение</span>
        , которое предлагает уникальные возможности для
        обеспечения работой людей с ограниченными возможностями здоровья по зрению,
        имеющим высшее образование психолога.
      </p>  
      <p class="peoples-content__text">
        Мы создаём платформу, где
        <span class="peoples-content__text-bold">каждый</span>
        сможет использовать своё образование и навыки
        для работы в качестве психологов-консультантов.
      </p>
      <p class="peoples-content__text">
        В профессиональной деятельности важен профессионализм, а не особенности здоровья и нашим
        приложением мы хотим внести свой вклад в создание реального инклюзивного общества.
      </p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import './index.scss';
</style>