<template>
  <Header/>
  <main id='main' class="main">
    <HeroSection/>
    <AboutSection/>
    <PeoplesSection/>
    <ConsultationSection/>
    <TasksSection/>
    <ContactsSection/>
    <SupportSection/>
  </main>
  <Footer/>
</template>

<script>
  import Header from '@/components/Header';
  import HeroSection from "@/components/sections/HeroSection";
  import AboutSection from "@/components/sections/AboutSection";
  import TasksSection from "@/components/sections/TasksSection";
  import ContactsSection from "@/components/sections/ContactsSection";
  import SupportSection from "@/components/sections/SupportSection";
  import PeoplesSection from "@/components/sections/PeoplesSection";
  import ConsultationSection from "@/components/sections/ConsultationSection";
  import Footer from "@/components/Footer";
  
  export default {
    name: 'App',
    components: {
      ConsultationSection,
      PeoplesSection,
      Header,
      HeroSection,
      AboutSection,
      TasksSection,
      ContactsSection,
      SupportSection,
      Footer,
    },
    data: () => {
      return {}
    },
    mounted() {
      document.body.setAttribute(`data-theme`, localStorage.getItem('data-theme') || 'default')
      document.body.setAttribute(`data-show-images`, localStorage.getItem('data-show-images') || 'true')
      document.body.setAttribute(`data-font-family`, localStorage.getItem('data-font-family') || 'sans-serif')
      document.body.setAttribute(`data-letter-spacing`, localStorage.getItem('data-letter-spacing') || 'default')
    }
  }
</script>

<style lang="scss">
  @import "./app.scss";
</style>
