<template>
  <a :href="`#${link}`" @click="clickHandler" class="button">
    <slot />
  </a>
</template>

<script>
  import scrollMixin from "@/mixins/scrollMixin";

  export default {
    name: 'Button',
    props: {
      link: {
        type: String,
        required: false
      },
      action: {
        type: String,
        required: false
      }
    },
    mixins: [scrollMixin],
    methods: {
      clickHandler(e) {
        if (this.action === 'SCROLL') {
          e.preventDefault()
          this.scrollTo(this.link)
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "./index.scss"; 
</style>