<template>
  <section id="tasks" class="section tasks">
    <div class="section-title">
      <span class="section-title__number">3</span>
      <h2 class="section-title__text" v-intersection="{section: 'tasks'}">
        О приложении
      </h2>
    </div>
    <p class="tasks-desc">
      Искусственный интеллект помогает определить источник проблемы,
      а не только работу с последствиями.
    </p>
    <div class="tasks-cards">
      <TaskCard
        v-for="task in tasks"
        :key="task.id"
        :task="task"
      />
    </div>
  </section>
</template>

<script>
  import TaskCard from "@/components/sections/TasksSection/TaskCard";

  export default {
    components: {
      TaskCard
    },

    data: () => {
      return {
        tasks: [
          {
            id: 1,
            imageUrl: 'img/tasks/task-1.png',
            text: 'Искусственный Интеллект производит диагностику психологического состояния'
          },
          {
            id: 2,
            imageUrl: 'img/tasks/task-2.png',
            text: 'Создаёт ваш психологический портрет'
          },
          {
            id: 3,
            imageUrl: 'img/tasks/task-3.png',
            text: 'Подбирает подходящие варианты психологов'
          },
          {
            id: 4,
            imageUrl: 'img/tasks/task-4.png',
            text: 'Обеспечивает соответствие между реальным психологическим портретом и специализацией психолога'
          },
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "./index.scss";
</style>