<template>
  <section id="support" class="section support">
    <div class="section-title">
      <span class="section-title__number">5</span>
      <h2
        v-intersection="{section: 'support'}"
        class="section-title__text"
      >
        ПОДДЕРЖАТЬ
      </h2>
    </div>
    <div class="support-text">
      <p>Ваша поддержка поможет нам привлечь больше людей в штат программистов, а также ускорит время создания
        приложения.</p>
      <p>Чтобы стать инвестором или спонсором проекта 'Психолог в кармане', просто нажмите кнопку 'Поддержать
        проект'</p>
    </div>
    <img class="support-image" src="img/support-image.png" alt="Картинка поддержки">
    <Button
      href="https://t.me/PinPocketAI"
      target="_blank"
      class="button support-button"
      :style="{'text-align': 'center'}"
    >
      Поддержать проект
    </Button>
  </section>
</template>

<script>
  import Button from "@/components/Button";

  export default {
    components: {Button},
    data: () => {
      return {}
    }
  }
</script>

<style lang="scss" scoped>
  @import "./index.scss";
</style>