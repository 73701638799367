<template>
  <svg class="menu-icon" width="42" height="28" viewBox="0 0 42 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.00001 27.5C2.36251 27.5 1.82776 27.284 1.39576 26.852C0.963758 26.42 0.748508 25.886 0.750008 25.25C0.750008 24.6125 0.966008 24.0778 1.39801 23.6458C1.83001 23.2138 2.36401 22.9985 3.00001 23H39C39.6375 23 40.1723 23.216 40.6043 23.648C41.0363 24.08 41.2515 24.614 41.25 25.25C41.25 25.8875 41.034 26.4223 40.602 26.8543C40.17 27.2863 39.636 27.5015 39 27.5H3.00001ZM3.00001 16.25C2.36251 16.25 1.82776 16.034 1.39576 15.602C0.963758 15.17 0.748508 14.636 0.750008 14C0.750008 13.3625 0.966008 12.8278 1.39801 12.3958C1.83001 11.9638 2.36401 11.7485 3.00001 11.75H39C39.6375 11.75 40.1723 11.966 40.6043 12.398C41.0363 12.83 41.2515 13.364 41.25 14C41.25 14.6375 41.034 15.1723 40.602 15.6043C40.17 16.0363 39.636 16.2515 39 16.25H3.00001ZM3.00001 5.00001C2.36251 5.00001 1.82776 4.78401 1.39576 4.35201C0.963758 3.92001 0.748508 3.38601 0.750008 2.75001C0.750008 2.11251 0.966008 1.57776 1.39801 1.14576C1.83001 0.713758 2.36401 0.498508 3.00001 0.500008H39C39.6375 0.500008 40.1723 0.716008 40.6043 1.14801C41.0363 1.58001 41.2515 2.11401 41.25 2.75001C41.25 3.38751 41.034 3.92226 40.602 4.35426C40.17 4.78626 39.636 5.00151 39 5.00001H3.00001Z" fill="#88D354"/>
  </svg>
</template>

<style lang="scss" scoped>
  .menu-icon {
    path {
      fill: var(--menu-button-color)
    }

    &-active {
      path {
        fill: var(--menu-button-color-bright);
      }
    }
  }
</style>