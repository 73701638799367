<template>
  <svg class="logo-icon" width="1044" height="1044" viewBox="0 0 1044 1044" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="1044" height="1044" rx="60" fill="#88D354"/>
    <path
      fill-rule="evenodd" clip-rule="evenodd" d="M176 281C224.601 281 264 241.601 264 193C264 144.399 224.601 105 176 105C127.399 105 88 144.399 88 193C88 241.601 127.399 281 176 281ZM445 283C493.601 283 533 243.601 533 195C533 146.399 493.601 107 445 107C396.399 107 357 146.399 357 195C357 243.601 396.399 283 445 283ZM264 526C264 574.601 224.601 614 176 614C127.399 614 88 574.601 88 526C88 477.399 127.399 438 176 438C224.601 438 264 477.399 264 526ZM176 946C224.601 946 264 906.601 264 858C264 809.399 224.601 770 176 770C127.399 770 88 809.399 88 858C88 906.601 127.399 946 176 946Z"
      fill="white"
    />
    <path
      fill-rule="evenodd" clip-rule="evenodd" d="M938.997 116C938.997 104.954 930.042 96 918.997 96H628C616.954 96 608 104.954 608 116V241.498C608 252.544 616.954 261.498 628 261.498H753.499C764.544 261.498 773.499 270.453 773.499 281.498L773.499 927C773.499 938.046 782.453 947 793.499 947H918.997C930.043 947 938.997 938.046 938.997 927L938.997 259.618C938.997 259.618 938.997 259.618 938.997 259.618C938.997 259.618 938.997 259.618 938.997 259.617V116Z"
      fill="white"
    />
  </svg>
</template>

<style lang="scss" scoped>
  .logo-icon {
    rect {
      fill: var(--logo-color)
    }

    path {
      fill: var(--bg-color);
    }
  }
</style>